<template>
  <div class="main-section">
    <div class="main-row">
      <sidebar v-if="$route.name !== 'browse'"/>
      <div class="main-data-col">
        <div class="right-data">
          <top-bar> </top-bar>
          <router-view/>

        </div>
      </div>
    </div>

    <transition name="loader-animation">
      <div class="loader" v-if="show_loader">
        <img loading="lazy"  src="@/assets/logo.png" alt="Filmstro Logo">
        <div id="loadingmessage" v-html="loading_message"></div>
        <svg  fill="white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="6" width="2.8" height="12"><animate id="a" begin="0;b.end-0.1s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="0;b.end-0.1s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect><rect x="5.8" y="6" width="2.8" height="12"><animate begin="a.begin+0.1s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="a.begin+0.1s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect><rect x="10.6" y="6" width="2.8" height="12"><animate begin="a.begin+0.2s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="a.begin+0.2s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect><rect x="15.4" y="6" width="2.8" height="12"><animate begin="a.begin+0.3s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="a.begin+0.3s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect><rect x="20.2" y="6" width="2.8" height="12"><animate id="b" begin="a.begin+0.4s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="a.begin+0.4s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect></svg>
      </div>
    </transition>

    <transition name="loader-animation">
      <div class="loader" v-if="false">
        <img loading="lazy"  src="@/assets/logo.png" alt="Filmstro Logo">
        <div id="loadingmesasage" class="edit-loading">
          <span :class="{'active':edit_loading_step==1}"><filmstro-icon name="check-img" type="image"/> Loading {{ $route.params.theme }} Information</span>
          <span :class="{'active':edit_loading_step==2}"><filmstro-icon name="check-img" type="image"/>Loading Audio files</span>
          <span :class="{'active':edit_loading_step==3}"><filmstro-icon name="check-img" type="image"/>Preparing Timeline</span>
        </div>
        <svg  fill="white" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="1" y="6" width="2.8" height="12"><animate id="a" begin="0;b.end-0.1s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="0;b.end-0.1s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect><rect x="5.8" y="6" width="2.8" height="12"><animate begin="a.begin+0.1s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="a.begin+0.1s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect><rect x="10.6" y="6" width="2.8" height="12"><animate begin="a.begin+0.2s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="a.begin+0.2s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect><rect x="15.4" y="6" width="2.8" height="12"><animate begin="a.begin+0.3s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="a.begin+0.3s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect><rect x="20.2" y="6" width="2.8" height="12"><animate id="b" begin="a.begin+0.4s" attributeName="y" calcMode="spline" dur="0.6s" values="6;1;6" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/><animate begin="a.begin+0.4s" attributeName="height" calcMode="spline" dur="0.6s" values="12;22;12" keySplines=".36,.61,.3,.98;.36,.61,.3,.98"/></rect></svg>
      </div>
    </transition>

    <!-- <pricing/> -->
    <login-modal/>
    <welcome-modal/>
    <edit-tutorial-modal/>
    <settings-modal />
    <!-- <debug-modal /> -->
    <about-filmstro-modal />
    <premium-feature-modal :title="`Oh no, you're out of free sessions`" :emoji="`🙃`" @close="setShowNoMoreFreeSessionsModal" v-if="show_no_more_free_sessions_modal"/>
    <premium-feature-modal  @close="closePremiumFeatureModal" v-if="show_premium_feature_modal"/>
    <LoginRequiredModal v-if="show_login_required_modal"/>
    <KeyboardShortcutsModal v-if="show_shortcuts_keyboard_modal" />

  </div>
</template>

<script>
import TopBar from './layout/TopBar.vue';
import Sidebar from './layout/Sidebar.vue';
// import Pricing from './components/Pricing.vue';
import LoginModal from './components/LoginModal.vue';
import WelcomeModal from './components/Modals/WelcomeModal.vue';
import EditTutorialModal from './components/Modals/EditTutorialModal.vue';
import PremiumFeatureModal from './components/Modals/PremiumFeatureModal.vue';
import SettingsModal from './components/Modals/SettingsModal.vue';
// import DebugModal from './components/Modals/DebugModal.vue';
import AboutFilmstroModal from '@/components/Modals/AboutFilmstroModal.vue';
import LoginRequiredModal from '@/components/Modals/LoginRequiredModal.vue';
import KeyboardShortcutsModal from '@/components/Modals/KeyboardShortcutsModal.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';


export default{
  components: {
    TopBar,
    Sidebar,
    LoginModal,
    WelcomeModal,
    EditTutorialModal,
    PremiumFeatureModal,
    SettingsModal,
    // DebugModal,
    AboutFilmstroModal,
    LoginRequiredModal,
    KeyboardShortcutsModal
  },
  watch: {
  '$route': (to, from) =>{
      document.body.classList.remove( from.name );
      document.body.classList.add( to.name );
    },
  },
  methods:{
    ...mapActions(['fetchFavourites','fetchUserSessions']),
    ...mapMutations(['setShowNoMoreFreeSessionsModal','setFlyoutContentView']),
    closePremiumFeatureModal(){
      this.$store.commit('setShowPremiumFeatureModal',false);
    }
  },
  computed:{
    ...mapGetters([
    'loading_message',
    'show_loader',
    'show_premium_feature_modal',
    'show_no_more_free_sessions_modal',
    'show_flyout_sidebar',
    'flyout_content_view',
    'show_login_required_modal',
    'show_shortcuts_keyboard_modal',
    'USER_IS_LOGGED_IN',
    'LOGGED_IN_USER_IS_ADMIN'
  ] ),
  ...mapGetters('edit',['edit_loading_step'])
  },
  async created(){

    await this.$store.dispatch( 'maybe_get_user_from_token' );
    await this.$store.dispatch( 'maybeVerifyToken' );
  },
  async mounted(){

    if (this.USER_IS_LOGGED_IN && window.Cookie.get('SHOULD_LOGOUT_WEB_APP_USER') ) {
      console.log('Logging user out...');
      await this.$store.dispatch( 'logout' );
      this.$router.push({ name: 'browse' } );
    }
    if( !this.USER_IS_LOGGED_IN && window.Cookie.get('filmstro_access_token') ){
      await this.$store.dispatch( 'verifyToken', window.Cookie.get('filmstro_access_token') );
      window.Cookie.erase('filmstro_access_token');
    }
     if(this.USER_IS_LOGGED_IN && this.LOGGED_IN_USER_IS_ADMIN){
      window.filmstroapp = window.filmstroapp || {}
       window.filmstroapp['storea'] = this.$store;
     }

    // let user_id = this.$store.gette
    // let user_id = false;


      document.addEventListener('dragenter',function(){
        document.body.classList.add('is-dragging');
      })

      document.addEventListener('dragleave',function(){
        document.body.classList.remove('is-dragging');
      })


    if( this.USER_IS_LOGGED_IN ){
      await this.fetchFavourites( this.$route?.params?.collection == 'favourites' );
      await this.fetchUserSessions();
      if( this.USER_IS_LOGGED_IN ){
        // check if we've showed the welcome modal before.
        if( !localStorage.getItem( 'viewed_welcome_modal' ) ){
          this.$store.commit('setShowWelcomeModal',true);
        }

      }
    }else{

    return;

    }
  }
}
</script>

<style scoped lang="scss">

.loader{
  position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgb(32 33 42 / 70%);
    z-index: 99999;
    text-align: center;
}

#loadingmessage{
    animation: fadeanim 2s infinite;
    margin-top: 10px;
    margin-bottom: 10px;
}
@keyframes fadeanim {
    0% {
        opacity: .2;
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: .3;
    }
}


.page-transition-enter-active,
  .page-transition-leave-active {
    transition: 100ms ease all;
  }
  .page-transition-enter-from,
  .page-transition-leave-to {
    opacity: 0;
    transform: translate(20px);
  }


  .loader-animation-enter-active {
    transition: all .5s 1s;
    }
    .loader-animation-leave-active {
    transition: all .5s;
    }
    .loader-animation-enter, .loader-animation-leave-to {
    opacity: 0;
    }



    .edit-loading {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 40px;
}

.edit-loading span {
    display: flex;
    align-items: center;
    gap: 10px;
    &.active{
      img{
        opacity: 1;
      }
    }
}

.edit-loading img {
    width: 12px;
    opacity: .4;
}

</style>
