let version = "3.1.217";
let APP_NAME = "Filmstro Pro";

import { createApp, defineAsyncComponent } from 'vue'
import { createI18n } from 'vue-i18n'

import App from './App.vue'
import router from './router'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import Notification from './includes/notification';
import * as Sentry from "@sentry/vue";
import localforage from 'localforage';
// Styles
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/styles/main.scss'
import 'simple-notify/dist/simple-notify.css'


import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'

import { calculateHeightForElement, get_environment } from './includes/helpers';






// Global Components
const FilmstroIcon = defineAsyncComponent(() => import("@/components/FilmstroIcon.vue"));
// const Breadcrumbs = defineAsyncComponent (()=> import( "@/components/BreadCrumbs.vue"));
const NothingSelected = defineAsyncComponent(() => import("@/components/NothingSelected.vue"));
// const FilmstroSliders = defineAsyncComponent (()=> import( "@/components/FilmstroSliders.vue"));
const Modal = defineAsyncComponent(() => import("@/components/Modal.vue"));
import PageLayout from "@/layout/PageLayout.vue";

import AccordionDirective from '@/directives/accordion';
import ToolTipsDirective from '@/directives/tooltips';
import UnfocusDirective from '@/directives/unfocus';


let app = createApp(App)
    .use(store)
    .use(router)
    .use(VueSweetalert2, {
        confirmButtonColor: '#41b882',
        cancelButtonColor: '#ff7674',
        iconHtml: '<img loading="lazy"  class="filmstro-icon-logo" src="https://filmstro.com/wp-content/uploads/2022/03/filmstro-logo.png" >',
    })
    .use(ContextMenu)




if (process.env.NODE_ENV === 'production') {

    Sentry.init({
        app,
        environment: get_environment(),
        dsn: "https://8cc1cf1e5d3c41c899b709c091e00fcb@o1374494.ingest.us.sentry.io/6681823",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false
            }),
            Sentry.httpClientIntegration(),
            Sentry.captureConsoleIntegration({
                levels: ["error"]
            })
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        sendDefaultPii: true,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/app\.filmstro\.com/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}


localforage.config({
    name: "Filmstro Web App V2",
    version: version,
    storeName: "filmstro",
    description: "Local DB for Filmstro"
});


window.localforage = localforage;

// or add them individually
// import {TippyDirective, Tippy, TippySingleton} from 'tippy.vue';

// app.directive('tippy', TippyDirective);
// app.component('tippy', Tippy);
// app.component('tippy-singleton', TippySingleton);

// import tippy,{followCursor} from 'tippy.js'

// tippy.setDefaultProps({
//     delay: [500, 50],
//     allowHTML: true,
//     animation: 'scale-subtle',
//     // arrow: '<svg width="16" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M0 6s1.796-.013 4.67-3.615C5.851.9 6.93.006 8 0c1.07-.006 2.148.887 3.343 2.385C14.233 6.005 16 6 16 6H0z"></svg>',
//     interactive: false,
//     theme: 'light',
//     plugins: [followCursor],
// });
// window.tippy = tippy;
// import 'tippy.js/dist/tippy.css';


// global properties
app.config.globalProperties.$notification = window.$notification = Notification;
app.config.globalProperties.$version = version;
app.config.globalProperties.$APP_NAME = APP_NAME;



// app.component('Breadcrumbs',Breadcrumbs);
app.component('FilmstroIcon', FilmstroIcon);
app.component('NothingSelected', NothingSelected);
app.component('Modal', Modal);
// app.component('FilmstroSliders',FilmstroSliders);
app.component('PageLayout', PageLayout);

app.directive('accordion', AccordionDirective);
app.directive('tooltip', ToolTipsDirective);
app.directive('unfocus', UnfocusDirective);



import messages from './locales';

const i18n = createI18n({
    // something vue-i18n options here ...
    locale: 'en',
    legacy: false,
    warnHtmlInMessage: false,
    messages: messages
})

app.use(i18n);

maybeCheckAndSetAffiliate();

app.mount('#app')

window.filmstroapp = window.filmstroapp || {};



function maybeCheckAndSetAffiliate() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('ref')) {
        let _ref_ = urlParams.get('ref');
        if (_ref_) {
            console.log(`Found referral user: ${_ref_}`)
            window.Cookie.set("affiliate_for_woocommerce", _ref_, 30);
        }
    }
}


// TODO: Remove from here
window.calculateHeight = () => {

    let top = document.querySelector('.main-header');
    let main = document.querySelector('.template-data.main-width');
    let info = document.querySelector('.info-section');
    let timeline = document.querySelector('.filmstro-timeline-section .main-width');
    let footer = document.querySelector('footer');
    if (top && main && info && timeline && footer) {
        let _top = top.getBoundingClientRect();
        // let _main = main.getBoundingClientRect();
        let _info = info.getBoundingClientRect();
        let _timeline = timeline.getBoundingClientRect();
        let _footer = footer.getBoundingClientRect();
        let h = window.innerHeight - _top.height - _info.height - _timeline.height - _footer.height;

        let d = document.querySelector('.template-data.main-width');

        setTimeout(() => {
            if (d) {
                let height = h - 5; //35 = offset
                d.style.height = `${height}px`;
                // let sl = document.querySelector('.template-slider-box')
                // if( sl ){
                //   sl.style.maxHeight = `${height - 15}px`; //15 = padding-top
                // calculateHeightForElement('.template-slider-box','.template-data',30);
                // }
            }
        }, 200);

        return h;
    }
}

if (process.env.NODE_ENV == "development") {
    window.filmstroapp.store = store;
};

window.addEventListener('resize', calculateHeight);
window.dispatchEvent(new Event('resize', { bubbles: true }))
window.calculateHeightForElement = calculateHeightForElement;